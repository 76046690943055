@tailwind base;
@tailwind components;
@tailwind utilities;

header a.active{
  @apply text-blue-stone-500 font-normal
}

@layer base {
  body {
    font-family: 'League Spartan', sans-serif;
  }

  h2 {
    @apply font-light text-4xl mb-3;
  }

  h3 {
    @apply text-blue-stone-500 font-light text-xl;
  }

  p {
    @apply font-light;
  }

  li {
    @apply p-4 text-lg font-light

    hover:text-blue-stone-500;
  }

  button {
    @apply text-white bg-blue-stone py-2 px-10 font-extralight uppercase text-lg mt-4 w-full xsm:w-auto

    hover:bg-blue-stone-500;
  }
  .home-bg {
    background-image: url('./assets/images/hero-image.jpg');
  }

  input {
    @apply w-full border-[1px] border-[#D3D3D3] p-2 rounded-none shadow-sm focus:outline-none text-xl font-light focus:border-blue-stone-500 focus:ring-1 focus:ring-blue-stone-500;
  }
  textarea {
    @apply w-full border-[1px] border-[#D3D3D3] p-2 rounded-none shadow-sm focus:outline-none text-xl font-light focus:border-blue-stone-500 focus:ring-1 focus:ring-blue-stone-500 resize-none h-[100px];
  }
}

/* .g-recaptcha {
  border:1px solid red;
 width: 100%;
} */

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}